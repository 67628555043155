/* Navbar.css */
body {
  margin: 0;
  padding: 0;
}
@keyframes vibrate {
  0% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
  100% { transform: rotate(0); }
}

.flinks{
  text-decoration: none;
  color: white;
}
.flinks:hover{
  color:#ceb97b;
}
.logo-img {
  animation: vibrate 0.5s infinite; 
}

.logoheading {
  font-family: 'Eczar', serif;
font-family: 'Proza Libre', sans-serif;
  text-shadow:  5px 5px 4px rgb(0, 0, 0);
  color: #CEB97B;
  font-size: 25px;
  letter-spacing: 2px;
}

.navBarWrapper{
  background-color: black;
  width: 100%;
  height: 75px;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
}
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  color: #fff; 
  height: 100%;
  width: calc(min(1920px, calc(100% - 60px)));
}

.logo {
  width: 97px;
  height: 57px;
}

.nav-links {
  list-style: none;
  display: flex;
  padding: 0;
}

.nav-links li {
  margin-left: 10px;

}

.nav-links li a {
  text-decoration: none;
  color: #fff;
}

.nav-button {
  text-decoration: none;
  padding: 8px 16px;
  font-size: 18px;
  border-radius: 4px;
  border: 2px solid #fff;
}

.nav-button:hover {
  transition: all 0.3s ease-out;
  background-color: #fff; 
  color: black; 
}

.nav-button.active {
  background-color: #fff;
  color: #0C0404;
  font-weight: 500;
  font-size: 20px;
}

/* Responsive Menu for Mobile */
.burger {
  display: none;
  cursor: pointer;
  width: 30px;
  height: 10px;
  background-color: #fff;
  position: relative;
}



@media screen and (max-width: 900px) {
  

  .logoheading{
    font-size: 18px;
  }

  .logo {
    margin-bottom: 0px;
    width:  94px;
    height: 54px;
  }

  .nav-button {
    text-align: center;
    display: block;
    width: 70px ;
    padding: 5px;
    font-size: 16px;
    margin-left: 0px;
  }

  .nav-button.active {
    font-weight: 500;
    font-size: 16px
  }
  
  .nav-button:hover {
    font-size: 18px;
  }


}
  @media screen and (max-width: 772px) {
    .navbar {
      flex-direction: column; 
      padding: 0px;
    }
  
    .logoheading
    {
      display: none; 
    }
  
    .navBarWrapper{
      height: auto;
    }
    .navbar{
      height: 98px;
      padding-bottom: 12px;
    }
    .nav-button {
    
      font-weight: bold;
      width: 50px;
      font-size: 12px;
      
    }
    
.nav-button.active {
  font-weight: bold;
  font-size: 12px;
}

  
  .nav-button:hover{
    font-size: 12px;
  }
    .nav-links {
      margin: 0px;
      padding: 2px; /* Add some spacing between the button and the links */
    }
  
    .nav-links li {
      margin: 0 10px; /* Add spacing between the links */
    }
  
    .nav-links li a {
      display: block;
      text-align: center;
      color: #fff;
      text-decoration: none;
    }
  
}

@media screen and (max-width: 400px) {
  
  .logoheading {
    display: none; /* Hide the logo headings on mobile screens */
  }

  .logo {
    width: 84px !important;
    height: 44px !important;/* Add some spacing between the logo and the menu */
  }
  .navbar{
    height: 90px;
    padding-bottom: 10px;
  }
  .nav-button {
    width: 50px;
    height: auto;
    font-size: 12px;
   }
  
.nav-button.active {
font-size: 12px;;
}


  .nav-button:hover {
    font-size: 12px;
  }
}