/* Footer Styles */
footer {
  background-color: #0c0404;
  color: #fff;
  text-align: center;
  height: auto;
  width: 100%;
}
.instagram-icon-container {
  display: inline-block;
}

.instagram-icon {
  font-size: 24px;
  color: white;
  transition: color 0.3s ease;
  cursor: pointer;
}

.instagram-icon-container:hover .instagram-icon {
  color: palevioletred;
}

.socialAccounts{
  
  display: flex;
  gap: 80px;
  justify-content: center;
  align-items: center;
}
.flinks{
  text-decoration: none;
  color: white;
}
.flinks:hover{
  color:#ceb97b;
}
.footerHeading {
  font-size: 24px;
  font-weight: bold;
  color: #ceb97b;
  text-align: left;
}
.footerL{
  font-family: poppins;
  font-weight: normal;
  text-align: left;
  font-size: 18px;
}

.blueText{
  font-size: 18px;
  color: #00A0E9;
}
p{
  font-size: 18px;
  text-align: left;
  font-family: poppins;
  font-weight: 100;
}
.footer-content {
  background-color: #0c0404;
  width: calc(min(1920px, calc(100% - 60px)));
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  
}
.displayColumn{
  display: flex;
  flex-direction: column;
}

.bottomLine{
  padding-top: 10px;
  border-top: 1px solid #ceb97b;
  width: calc(min(1920px, calc(100% - 60px)));
  display: flex;
  justify-content: space-around;
  margin-inline: auto;
}
.bottomLineservices{
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border-top: 1px solid #ceb97b;
  width: calc(min(1920px, calc(100% - 60px)));
  display: flex;
  justify-content: center;
    gap: 10px;
  margin-inline: auto;
}
.footerLogo{
  width: 94px;
  height: 54px;
}

.footerLogoservices{
  border-radius: 50;
  width: 100px;
  height: 80px;
  padding: 15px;
}
.footerserviceheading{
  font-size: 18px;
}
.certifications{
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media screen and (max-width: 900px) {
  .footerL{
   
    font-size: 16px;
  }
  
.footerLogoservices{
  width: 90px;
  height: 70px;
  padding: 15px;
}
.footerserviceheading{
  font-size: 16px;
}

  .blueText{
    font-size: 16px;
  }
  p{
    font-size: 16px;
  }
  
  .footerLogo{
    
    width: 74px;
    height: 44px;
  }
  
}


@media screen and (max-width: 800px) {
  .footer-content {
    flex-direction: column;
  }
}


@media screen and (max-width: 600px) {
  .footerLogo{
    display: none;
  }
  
.footerLogoservices{
  border-radius: 50;
  width: 60px;
  height: 54px;
  padding: 0px;
}
.footerserviceheading{
  font-size: 14px;
}
.certifications{
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
  .footerHeading{
    font-size: 20px;
  }
  .footerL{

    font-size: 14px;
  }
  
  .blueText{
    font-size: 14px;
  }
  p{
    font-size: 14px;
  }
  
  
}
